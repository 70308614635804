import React from "react";
import styles from "./ProfileUserStatistics.module.css";
import Typography from "../../atoms/Typography/Typography";
import Icon from "../../atoms/Icon/Icon";
import { useAuth } from "../../../AthContext";
import { useTranslation } from "react-i18next";

const ProfileUserStatistics = ({playerData}) => {
  const { isMobile, playerInfo } = useAuth();
  const { t } = useTranslation();
  
  const statsSource = playerData || playerInfo;

  if (!statsSource) {
    // Return loading state or placeholder until playerData or playerInfo is fetched
    return <div>{t("dataLoaderScreen.loading")}</div>;
  }
  const getCategoryName = (categoryId) => {
    switch (categoryId) {
      case 1:
        return "General";
      case 2:
        return "TVMusic";
      case 3:
        return "Science";
      case 4:
        return "Business";
      case 5:
        return "Sports";
      case 6:
        return "Food";
      case 7:
        return "Celebrities";
      case 8:
        return "Geography";
      case 9:
        return "History";
      default:
        return "";
    }
  };

  return (
    <div className={styles.profileExtraSection}>
      <div className={styles.infoLinesArea}>
        <div className={styles.infoAreaHeader}>
          {t("mainNav.profileTab.stats.statistics.stats")}
        </div>
        {/* <div className={styles.line}>
          <div>
            <Typography
              fontWeight={"font400"}
              variant={"caption"}
              color="colorBlack"
              additionalClass={styles.grey}
            >
              {t("mainNav.profileTab.stats.statistics.wins")}
            </Typography>
            {playerInfo?.userStats?.numWins}
          </div>
          <div>
            <Typography
              fontWeight={"font400"}
              variant={"caption"}
              color="colorBlack"
              additionalClass={styles.grey}
            >
              {t("mainNav.profileTab.stats.statistics.loses")}
            </Typography>
            {playerInfo?.userStats?.numLoses}
          </div>
        </div> */}
        {/* <div className={styles.hr} /> */}

        <div className={styles.line}>
          <div>
            <Typography
              fontWeight={"font400"}
              variant={"caption"}
              color="colorBlack"
              additionalClass={styles.grey}
            >
              {t("mainNav.profileTab.stats.statistics.games")}
            </Typography>
            {statsSource?.userStats?.numGames}
          </div>
          <div>
            <Typography
              fontWeight={"font400"}
              variant={"caption"}
              color="colorBlack"
              additionalClass={styles.grey}
            >
              {t("mainNav.profileTab.stats.statistics.maxTrophies")}
            </Typography>
            {statsSource?.userStats?.maxTrophies}
          </div>
        </div>
        {/* <div className={styles.hr} />
        <div className={styles.line}>
          <div>
            <Typography
              fontWeight={"font400"}
              variant={"caption"}
              color="colorBlack"
              additionalClass={styles.grey}
            >
              {t("mainNav.profileTab.stats.statistics.winLoseRatio")}
            </Typography>
            {playerInfo?.userStats?.ratio !== undefined &&
              playerInfo?.userStats?.ratio.toFixed(2)}
          </div>
          <div>
            <Typography
              fontWeight={"font400"}
              variant={"caption"}
              color="colorBlack"
              additionalClass={styles.grey}
            >
              {t("mainNav.profileTab.stats.statistics.averageWins")}
            </Typography>
            {playerInfo?.userStats?.avgScore}
          </div>
        </div> */}
        <div className={styles.hr} />
        {/* <div className={styles.line}>
          <div>
            <Typography
              fontWeight={"font400"}
              variant={"caption"}
              color="colorBlack"
              additionalClass={styles.grey}
            >
              {t("mainNav.profileTab.stats.statistics.perfectGames")}
            </Typography>
            {playerInfo?.userStats?.perfectGames}
          </div>
          <div>
            <Typography
              fontWeight={"font400"}
              variant={"caption"}
              color="colorBlack"
              additionalClass={styles.grey}
            >
              {t("mainNav.profileTab.stats.statistics.averageAnswerTime")}
            </Typography>
            {playerInfo?.userStats?.avgAnswerTime}
          </div>
        </div>
        <div className={styles.hr} /> */}
        {/* <div className={styles.line}>
          <div>
            <Typography
              fontWeight={"font400"}
              variant={"caption"}
              color="colorBlack"
              additionalClass={styles.grey}
            >
              {t("mainNav.profileTab.stats.statistics.currentWinningStreak")}
            </Typography>
            {playerInfo?.userStats?.currentWinningStreak}
          </div>
          <div>
            <Typography
              fontWeight={"font400"}
              variant={"caption"}
              color="colorBlack"
              additionalClass={styles.grey}
            >
              {t("mainNav.profileTab.stats.statistics.maxWinningStreak")}
            </Typography>
            {playerInfo?.userStats?.maxWinningStreak}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ProfileUserStatistics;
