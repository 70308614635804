import React, { useEffect, useState } from "react";
import styles from "./LeaderboardsTemplate.module.css";
import Typography from "../../atoms/Typography/Typography";
import GameModeSlider from "../../molecules/GameModeSlider/GameModeSlider";
import LeaderboardContainer from "../../organisms/LeaderboardContainer/LeaderboardContainer";
import { useAuth } from "../../../AthContext";
import {
  getAllTimeData,
  getFriendsLeaderboardData,
  getSpecialQuizLeaderboardData,
} from "../../../apiHelpers";
import LoadingSpinner from "../../molecules/LoadingSpinner/LoadingSpinner";
import { useLoginExpired } from "../../../customHooks";

const LeaderboardsTemplate = () => {
  const [selectedGameMode, setSelectedGameMode] = useState("friends");
  const [loading, setLoading] = useState(false);
  const loginExpired = useLoginExpired();
  const { isMobile, translateText, updateLeaderboardData, leaderboardData } =
    useAuth();
  const [filteredFriendsData, setFilteredFriendsData] = useState([]);
  const [filteredSpecialQuizzesData, setFilteredSpecialQuizzesData] = useState(
    []
  );
  const [filteredSpecialQuizzesDataTotal, setFilteredSpecialQuizzesDataTotal] =
    useState([]);

    useEffect(() => {
      const fetchAllLeaderboardData = async () => {
        try {
          // Fetch friends leaderboard data
          const friendsLeaderboardData = await getFriendsLeaderboardData(0);
          setFilteredFriendsData(friendsLeaderboardData);
          console.log("Friends leaderboard loaded");
    
          // Fetch the rest of the leaderboard data
          const [globalLeads, weeklyLeads, allTimeLeads] = await Promise.all([
            getAllTimeData(0),
            getSpecialQuizLeaderboardData(0, "weekly"),
            getSpecialQuizLeaderboardData(0, "alltime"),
          ]);
    
          // Process the global and special quizzes data
          updateLeaderboardData(globalLeads);
          setFilteredSpecialQuizzesData([
            weeklyLeads.userData,
            ...weeklyLeads.leaderboardList,
          ]);
          setFilteredSpecialQuizzesDataTotal([
            allTimeLeads.userData,
            ...allTimeLeads.leaderboardList,
          ]);
          console.log("Global and special quizzes leaderboard loaded");
        } catch (error) {
          console.error("Error fetching leaderboard data:", error);
          if (error.response?.status === 401) {
            loginExpired();
          }
        }
      };
    
      fetchAllLeaderboardData();
    }, []);

  const handleGameModeChange = (index) => {
    // Map the selected index to the corresponding game mode
    const modes = [
      "friends",
      "global",
      "specialQuizzesWeekly",
      "specialQuizzesTotal",
    ];
    setSelectedGameMode(modes[index]);
  };

  return (
    <div className={styles.leaderboardsTemplate}>
      <Typography
        variant={isMobile ? "heading2" : "headingL"}
        fontWeight="font700"
        color="colorBlack"
        additionalClass={styles.leaderboardTitle}
      >
        {translateText("mainNav.leaderboardTab.title")}
      </Typography>
      <div className={styles.slideContainer}>
        <GameModeSlider
          mode
          className={styles.slider}
          onChange={handleGameModeChange}
        />
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <LeaderboardContainer
          gameMode={selectedGameMode}
          filteredFriendsData={filteredFriendsData}
          setFilteredFriendsData={setFilteredFriendsData}
          leaderboardData={leaderboardData}
          filteredSpecialQuizzesData={filteredSpecialQuizzesData}
          filteredSpecialQuizzesDataTotal={filteredSpecialQuizzesDataTotal}
        />
      )}
    </div>
  );
};

export default LeaderboardsTemplate;
