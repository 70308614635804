import React, { useState, useEffect, useCallback } from "react";
import styles from "./SpecialQuizManagement.module.css"; // Separate CSS for styling
import {
  getSpecialQuizQuestions,
  deleteSpecialQuizQuestions,
  setSpecialQuizQuestions,
} from "../../../apiHelpers";

const SpecialQuizManagement = ({
  specialQuizTitle,
  specialQuizId,
  backFunction,
  questionsNum,
  updaterFunction,
}) => {
  const [key, setKey] = useState(""); // Stores the entered key
  const [questions, setQuestions] = useState(""); // Stores the entered questions JSON
  const [loading, setLoading] = useState(true);

  // Fetching function for the questions of the special quiz
  const fetchQuestions = useCallback(async () => {
    try {
      const questions = await getSpecialQuizQuestions(specialQuizId, 1);
      // Removing _id and specialQuizId from each question
      const sanitizedQuestions = questions.data.map(
        ({ _id, specialQuizId, ...rest }) => rest
      );
      // Format the questions JSON
      const existingQuestions =
        JSON.stringify(sanitizedQuestions, null, 2) === "[]"
          ? ""
          : JSON.stringify(sanitizedQuestions, null, 2);
      // if the array is empty, set the questions to null
      setQuestions(existingQuestions);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch questions:", error);
    }
  }, [specialQuizId]);

  // Fetch the questions when the component mounts
  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  // Check if the form is valid
  const isFormValid = key && questions;

  // Handler function for adding questions
  const addQuestionsHandler = async () => {
    try {
      // Parse the JSON input
      const questionsJson = JSON.parse(questions);
      // Delete existing questions
      await deleteSpecialQuizQuestions(specialQuizId, key);
      // Set the new questions
      await setSpecialQuizQuestions(specialQuizId, key, questionsJson);
      alert("Questions added successfully!");
      updaterFunction(); // Update the parent component
      backFunction(); // Navigate back
    } catch (error) {
      console.error("Failed to add questions:", error);
      alert("Failed to add questions!");
    }
  };

  if (!specialQuizTitle || !specialQuizId || loading) {
    return (
      <div>
        <p>Loading...</p>
        <button className={styles.BackButton} onClick={backFunction}>
          Back
        </button>
      </div>
    );
  }

  return (
    <div className={styles.SpecialQuizManagement}>
      <button className={styles.BackButton} onClick={backFunction}>
        Back
      </button>
      <div>
        <h1>{specialQuizTitle}</h1>
      </div>
      {questionsNum && (
        <div className={styles.error}>
          This Special Quiz already has questions.
        </div>
      )}
      <p>Enter the generic key:</p>
      <input
        type="password"
        placeholder="Enter generic key"
        className={styles.inputField}
        value={key}
        onChange={(e) => setKey(e.target.value)}
      />
      <p>Enter Questions Object in JSON format:</p>
      <textarea
        placeholder="Paste your JSON here"
        className={styles.textareaField}
        value={questions}
        onChange={(e) => {
          setQuestions(e.target.value); // Update questions on change
        }}
        rows={20} // More rows for better visibility
        cols={80} // Wider columns for readability
      ></textarea>
      <button
        className={styles.addButton}
        onClick={addQuestionsHandler}
        disabled={!isFormValid} // Disable button if form is not valid
      >
        Add Questions
      </button>
    </div>
  );
};

export default SpecialQuizManagement;
