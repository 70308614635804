import React, { useState, useEffect } from "react";
import styles from "./SpecialQuizView.module.css";
import {
  getSpecialQuizIdsAdmin,
  getSpecialQuizInfo,
  getSpecialQuizQuestions,
} from "../../../apiHelpers";
import SpecialQuizManagement from "../SpecialQuizManagement/SpecialQuizManagement";

const SpecialQuizView = ({ setShowQuizView }) => {
  // State to hold special quiz data and loading state
  const [quiz, setQuiz] = useState([]);
  const [loading, setLoading] = useState(true); // Initially loading is true
  const [selectedQuiz, setSelectedQuiz] = useState(null);

  useEffect(() => {
    // Fetch quiz IDs and then fetch the quiz details
    getSpecialQuizIdsAdmin().then((ids) => {
      // Use Promise.all to fetch info for each quiz in parallel
      Promise.all(
        ids.map((id) =>
          getSpecialQuizInfo(id).then((response) => {
            return response;
          })
        )
      ).then((quizData) => {
        // Filter out any null or invalid results
        const validQuizzes = quizData.filter((quiz) => quiz !== null);

        // Now fetch the number of questions for each valid quiz
        Promise.all(
          validQuizzes.map((quiz) =>
            getSpecialQuizQuestions(quiz._id).then((questionsResponse) => {
              // Check the response and add the questions count to the quiz object
              quiz.numOfQuestions = questionsResponse?.length
                ? questionsResponse.length
                : 0;
              return quiz; // Return the updated quiz object
            })
          )
        ).then((updatedQuizzes) => {
          // Sort the Quizzes by date
          const sortedQuizzes = updatedQuizzes.sort((a, b) => {
            const dateA = new Date(a.startingAt);
            const dateB = new Date(b.startingAt);
            return dateA - dateB; // Ascending order: earliest date first
          });

          // Set the tournaments state and turn off loading
          setQuiz(sortedQuizzes);
          setLoading(false); // Set loading to false once data is fetched
        });
      });
    });
  }, []); // Empty dependency array means this will run only once when component mounts

  // Update specific a quiz's questions count based on quiz_id , call the getQuestionsLength API
  const updateSpecialQuizCount = (quiz_id) => {
    getSpecialQuizQuestions(quiz_id).then((questionsResponse) => {
      // Find the quiz object with the matching ID
      const updatedQuizzes = quiz.map((quiz_item) => {
        if (quiz_item._id === quiz_id) {
          // Update the number of questions
          quiz_item.numOfQuestions = questionsResponse?.length
            ? questionsResponse.length
            : 0;
        }
        return quiz_item; // Return the updated quiz object
      });
      setQuiz(updatedQuizzes); // Update the state with the new data
    });
  };

  const formatDateRange = (startingAt, endingAt) => {
    const date = new Date(startingAt); // Convert to Date object
    const date2 = new Date(endingAt); // Convert to Date object

    // Extract Day, Month, Year, and Day Name for starting date
    const dayStart = date.getDate(); // Day of the month
    const monthStart = date.toLocaleString("default", { month: "short" }); // Short month name (e.g., Nov)
    const yearStart = date.getFullYear(); // Full year (e.g., 2024)

    // Extract Day, Month, Year, and Day Name for ending date
    const dayEnd = date2.getDate(); // Day of the month
    const monthEnd = date2.toLocaleString("default", { month: "short" }); // Short month name (e.g., Nov)
    const yearEnd = date2.getFullYear(); // Full year (e.g., 2024)

    // Return the following formating ``DayStart-MonthStart-YearStart -> DayEnd-MonthEnd-YearEnd``
    return `${dayStart}-${monthStart}-${yearStart} until ${dayEnd}-${monthEnd}-${yearEnd}`;
  };

  if (loading) {
    return <p>Loading special quizzes...</p>; // Show loading message while data is being fetched
  }

  // If a quiz is selected, show the quiz management view
  if (selectedQuiz) {
    return (
      <SpecialQuizManagement
        specialQuizTitle={selectedQuiz?.title}
        specialQuizId={selectedQuiz?.id}
        backFunction={() => setSelectedQuiz(null)}
        questionsNum={selectedQuiz?.numOfQuestions}
        updaterFunction={() => {
          updateSpecialQuizCount(selectedQuiz?.id);
        }}
      />
    );
  }

  return (
    <div className={styles.SpecialQuizView}>
      <p>Green Quizzes have questions. Red Quizzes do not.</p>
      <button
        className={styles.BackButton}
        onClick={() => setShowQuizView(false)}
      >
        Back
      </button>
      <ol>
        {quiz &&
          quiz.map((quiz_item, index) => (
            <li key={index}>
              {/* Format the date using the formatDate function */}
              <button
                onClick={() => {
                  setSelectedQuiz({
                    title:
                      formatDateRange(
                        quiz_item.startingAt,
                        quiz_item.endingAt
                      ) +
                      " | " +
                      quiz_item.name +
                      " | #Q:" +
                      quiz_item.numOfQuestions,
                    id: quiz_item._id,
                    numOfQuestions: quiz_item.numOfQuestions,
                  });
                }}
                className={`${styles.tournamentButton} ${
                  quiz_item.numOfQuestions === 0 ||
                  quiz_item.numOfQuestions === undefined
                    ? styles.redButton // Apply red button style if no questions
                    : ""
                }`}
              >
                {`${formatDateRange(
                  quiz_item.startingAt,
                  quiz_item.endingAt
                )} | ${quiz_item.name} | #Q: ${quiz_item.numOfQuestions}`}
              </button>
            </li>
          ))}
      </ol>
    </div>
  );
};

export default SpecialQuizView;
