import React, { useState } from "react";

const Icon = ({
  name = "",
  folder,
  size = 24,
  onClick,
  additionalClass = "",
  style = {},
}) => {
  const [imageError, setImageError] = useState(false);
  const iconName = name.endsWith(".png") ? name.slice(0, -4) : name;
  let iconPath;

  try {
    iconPath = require(`../../../assets/images/${folder}/${iconName}.png`);
  } catch (error) {
    console.error(`Icon not found: ${iconName}. Loading default icon.`);
    iconPath = require(`../../../assets/images/other_icons/default.png`);
  }

  const handleError = () => {
    setImageError(true);
  };

  if (imageError) return null;

  return (
    <img
      className={additionalClass}
      src={iconPath}
      alt={`${name} icon`}
      width={size}
      style={{ cursor: onClick ? "pointer" : "default", ...style }}
      onClick={onClick}
      onError={handleError} // Triggered if the image fails to load
    />
  );
};

export default Icon;
