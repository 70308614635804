import React, { useState, useEffect, useCallback } from "react";
import styles from "./TournamentManagement.module.css"; // Separate CSS for styling
import {
  deleteTournamentQuestions,
  setTournamentQuestions,
  getTournamentQuestions,
} from "../../../apiHelpers";

const TournamentManagement = ({
  tournamentTitle,
  tournamentId,
  backFunction,
  questionsNum,
  updaterFunction,
}) => {
  const [key, setKey] = useState(""); // Stores the entered key
  const [questions, setQuestions] = useState(""); // Stores the entered questions JSON

  const [loading, setLoading] = useState(true);

  // Fetching function for the questions of the special quiz
  const fetchQuestions = useCallback(async () => {
    try {
      const questions = await getTournamentQuestions(tournamentId, 1);
      // Removing _id and specialQuizId from each question
      const sanitizedQuestions = questions.data.map(
        ({ _id, specialQuizId, ...rest }) => rest
      );
      // Format the questions JSON
      const existingQuestions =
        JSON.stringify(sanitizedQuestions, null, 2) === "[]"
          ? ""
          : JSON.stringify(sanitizedQuestions, null, 2);
      // if the array is empty, set the questions to null
      setQuestions(existingQuestions);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch questions:", error);
    }
  }, [tournamentId]);

  // Fetch the questions when the component mounts
  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  // Check if the form is valid
  const isFormValid = key && questions;

  // Handler function
  const addQuestionsHandler = async () => {
    try {
      // Turn questions object to JSON
      const questionsJson = JSON.parse(questions);
      // Delete existing questions
      await deleteTournamentQuestions(tournamentId, key);
      // Send the request to the API
      await setTournamentQuestions(tournamentId, key, questionsJson);
      alert("Questions added successfully!"); // Show an alert for successful addition
      updaterFunction(); // Update the questions
      backFunction(); // Go back to the previous page
    } catch (error) {
      console.log(error);
      alert("Failed to add questions!"); // Show an alert for failed addition
    }
  };

  if (!tournamentTitle || !tournamentId || loading) {
    return (
      <div>
        <p>Loading...</p>
        <button className={styles.BackButton} onClick={backFunction}>
          Back
        </button>
      </div>
    );
  }
  return (
    <div className={styles.TournamentManagement}>
      <button className={styles.BackButton} onClick={backFunction}>
        Back
      </button>
      <div>
        <h1>{tournamentTitle}</h1>
      </div>
      {questionsNum && (
        <div className={styles.error}>
          This tournament already has questions.
        </div>
      )}
      <p>Enter the generic key:</p>
      <input
        type="password"
        placeholder="Enter the generic key"
        className={styles.inputField}
        value={key}
        onChange={(e) => setKey(e.target.value)} // Update key state
      />

      {/* Add a big textbox for the JSON input */}
      <p>Enter Questions Object in JSON format:</p>
      <textarea
        placeholder="Paste your JSON here"
        className={styles.textareaField} // Add styling in CSS for a large textbox
        value={questions}
        onChange={(e) => setQuestions(e.target.value)} // Update questionsJson state
        rows={10} // Number of visible rows
        cols={100} // Number of visible columns
      ></textarea>
      <button
        className={styles.addButton}
        onClick={addQuestionsHandler}
        disabled={!isFormValid} // Disable if form is not valid
      >
        Add Questions
      </button>
    </div>
  );
};

export default TournamentManagement;
