import React, { useState } from "react";
import styles from "./AdminPanel.module.css";
import TournamentCreation from "../../organisms/TournamentCreation/TournamentCreation";
import TournamentView from "../../organisms/TournamentView/TournamentView";
import SpecialQuizCreation from "../../organisms/SpecialQuizCreation/SpecialQuizCreation";
import SpecialQuizView from "../../organisms/SpecialQuizView/SpecialQuizView";

const AdminPanel = () => {
  const [isUnlocked, setIsUnlocked] = useState(false); // Determines if the page is unlocked
  const [password, setPassword] = useState(""); // Stores the entered password
  const [activeView, setActiveView] = useState(""); // Tracks the current active view

  // Get the correct password from .env file
  const correctPassword = process.env.REACT_APP_ADMIN_KEY;

  // Handle password submission
  const handlePasswordSubmit = (e) => {
    e.preventDefault(); // Prevent page refresh
    if (password === correctPassword) {
      setIsUnlocked(true); // Unlock the page
    } else {
      alert("Incorrect password!"); // Show an alert for incorrect password
    }
  };

  // View mapping for dynamic rendering
  const views = {
    TournamentCreation: (
      <TournamentCreation setShowCreation={() => setActiveView("")} />
    ),
    TournamentView: <TournamentView setShowView={() => setActiveView("")} />,
    SpecialQuizCreation: (
      <SpecialQuizCreation setShowQuizCreation={() => setActiveView("")} />
    ),
    SpecialQuizView: (
      <SpecialQuizView setShowQuizView={() => setActiveView("")} />
    ),
  };

  if (!isUnlocked) {
    return (
      <div className={styles.AdminPanelPage}>
        <h1 className={styles.AdminHeader}>Admin Panel</h1>
        <form className={styles.AdminForm} onSubmit={handlePasswordSubmit}>
          <input
            className={styles.AdminInput}
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)} // Update password state
          />
          <button className={styles.AdminButton} type="submit">
            Unlock
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className={styles.AdminPanelPage}>
      {!activeView ? (
        <>
          <h1>Welcome to the Admin Panel</h1>
          <ol>
            {[
              { label: "Create Tournament", view: "TournamentCreation" },
              { label: "View Tournaments", view: "TournamentView" },
              { label: "Create Special Quiz", view: "SpecialQuizCreation" },
              { label: "View Special Quizzes", view: "SpecialQuizView" },
            ].map(({ label, view }) => (
              <li key={view}>
                <button
                  className={styles.createButton}
                  onClick={() => setActiveView(view)}
                >
                  {label}
                </button>
              </li>
            ))}
          </ol>
        </>
      ) : (
        views[activeView]
      )}
    </div>
  );
};

export default AdminPanel;
