import React, { useState, useEffect } from "react";
import styles from "./TournamentView.module.css"; // Separate CSS for styling
import {
  getTournamentIdsAdmin,
  getTournamentInfo,
  getTournamentQuestions,
} from "../../../apiHelpers";

import TournamentManagement from "../TournamentManagement/TournamentManagement";

const TournamentView = ({ setShowView }) => {
  // State to hold tournament data and loading state
  const [tournaments, setTournaments] = useState([]);
  const [loading, setLoading] = useState(true); // Initially loading is true
  const [selectedTournament, setSelectedTournament] = useState(null);

  useEffect(() => {
    // Fetch tournament IDs and then fetch the tournament details
    getTournamentIdsAdmin().then((ids) => {
      // Use Promise.all to fetch info for each tournament in parallel
      Promise.all(
        ids.map((id) =>
          getTournamentInfo(id).then((response) => {
            // Destructure to get only the tournament object
            if (response.code === 0) {
              return response.tournament; // We only care about the 'tournament' data
            }
            return null; // In case of an error, return null or handle it accordingly
          })
        )
      ).then((tournamentsData) => {
        // Filter out any null or invalid results
        const validTournaments = tournamentsData.filter(
          (tournament) => tournament !== null
        );

        // Now fetch the number of questions for each valid tournament
        Promise.all(
          validTournaments.map((tournament) =>
            getTournamentQuestions(tournament._id).then((questionsResponse) => {
              // Check the response and add the questions count to the tournament object
              tournament.numOfQuestions = questionsResponse?.length
                ? questionsResponse.length
                : 0;

              return tournament; // Return the updated tournament object
            })
          )
        ).then((updatedTournaments) => {
          // Sort the tournaments by date
          const sortedTournaments = updatedTournaments.sort((a, b) => {
            const dateA = new Date(a.startingAt);
            const dateB = new Date(b.startingAt);
            return dateA - dateB; // Ascending order: earliest date first
          });

          // Set the tournaments state and turn off loading
          setTournaments(sortedTournaments);
          setLoading(false); // Set loading to false once data is fetched
        });
      });
    });
  }, []); // Empty dependency array means this will run only once when component mounts

  // Update specific a tournament's questions count based on tournament_id , call the getTournamentQuestionsAPI
  const updateTournamentQuestionsCount = (tournament_id) => {
    getTournamentQuestions(tournament_id).then((questionsResponse) => {
      // Find the tournament object with the matching ID
      const updatedTournaments = tournaments.map((tournament) => {
        if (tournament._id === tournament_id) {
          // Update the number of questions
          tournament.numOfQuestions = questionsResponse?.length
            ? questionsResponse.length
            : 0;
        }
        return tournament; // Return the updated tournament object
      });
      setTournaments(updatedTournaments); // Update the state with the new data
    });
  };

  const formatDate = (startingAt) => {
    const date = new Date(startingAt); // Convert to Date object

    // Extract Day, Month, Year, and Day Name
    const day = date.getDate(); // Day of the month
    const month = date.toLocaleString("default", { month: "short" }); // Short month name (e.g., Nov)
    const year = date.getFullYear(); // Full year (e.g., 2024)

    return `${day} - ${month} - ${year}`; // Format as "DayName - Day - Month - Year"
  };

  if (loading) {
    return <p>Loading tournaments...</p>; // Show loading message while data is being fetched
  }

  if (selectedTournament) {
    return (
      <TournamentManagement
        tournamentTitle={selectedTournament?.title}
        tournamentId={selectedTournament?.id}
        backFunction={() => setSelectedTournament(null)}
        questionsNum={selectedTournament?.numOfQuestions}
        updaterFunction={() => {
          updateTournamentQuestionsCount(selectedTournament?.id);
        }}
      />
    );
  }

  return (
    <div className={styles.TournamentViewPage}>
      <p>Green Tournaments have questions. Red Tournaments do not.</p>
      <button className={styles.BackButton} onClick={() => setShowView(false)}>
        Back
      </button>
      <ol>
        {tournaments &&
          tournaments.map((tournament, index) => (
            <li key={index}>
              {/* Format the date using the formatDate function */}
              <button
                onClick={() => {
                  setSelectedTournament({
                    title:
                      formatDate(tournament.startingAt) +
                      " / #Q:" +
                      tournament.numOfQuestions,
                    id: tournament._id,
                    numOfQuestions: tournament.numOfQuestions,
                  });
                }}
                className={`${styles.tournamentButton} ${
                  tournament.numOfQuestions === 0 ||
                  tournament.numOfQuestions === undefined
                    ? styles.redButton // Apply red button style if no questions
                    : ""
                }`}
              >
                {`${formatDate(tournament.startingAt)}  / #Q: ${
                  tournament.numOfQuestions
                }`}
              </button>
            </li>
          ))}
      </ol>
    </div>
  );
};

export default TournamentView;
