import React, { useState } from "react";
import styles from "./SpecialQuizCreation.module.css";
import Icon from "../../atoms/Icon/Icon";
import { createSpecialQuiz } from "../../../apiHelpers";

// Function to dynamically import all image filenames from the specified directory
const importAllImages = (r) => {
  let images = {};
  r.keys().map((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const SpecialQuizCreation = ({ setShowQuizCreation }) => {
  // Dynamically load images from the special_quizzes folder
  const backgroundImages = importAllImages(
    require.context(
      "../../../assets/images/special_quizzes",
      false,
      /\.(jpg|jpeg|png|gif)$/
    )
  );

  // Convert the keys of the images object into an array
  const backgroundImageOptions = Object.keys(backgroundImages);

  const backgroundColorOptions = [
    "blueBackground",
    "lightPurpleBackground",
    "brownBackground",
    "darkPurpleBackground",
    "greenBackground",
    "redBackground",
  ];

  // Fields for first page
  const [quizKey, setQuizKey] = useState("");
  const [startDate, setStartDate] = useState("");
  const [error, setError] = useState("");

  // Fields for second page
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [backgroundImage, setBackgroundImage] = useState(
    backgroundImageOptions[0]
  );
  const [backgroundColor, setBackgroundColor] = useState(
    backgroundColorOptions[0]
  );

  // Page tracking
  const [currentPage, setCurrentPage] = useState(1); // 1 for first page, 2 for second page

  const isTuesday = (date) => new Date(date).getUTCDay() === 2; // Tuesday check

  const createQuizHandler = () => {
    if (!isFirstPageValid || !isSecondPageValid) {
      setError("Please ensure all fields are valid before proceeding.");
      return;
    }

    setError("");
    const quizPayload = {
      key: quizKey.trim(), // Trim spaces from quizKey
      startingAt: startDate.trim() + "T00:00:00.000Z", // Assuming date fields are string and need trimming (for safety)
      endingAt: getNextMonday(startDate) + "T23:59:59.000Z", // Get the next Monday and add the time
      name: name.trim(), // Trim spaces from name
      description: description.trim(), // Trim spaces from description
      backgroundImage: backgroundImage
        .replace(/\.(jpg|jpeg|png|gif)$/i, "")
        .trim(), // Remove file extension and trim spaces
      backgroundColor: backgroundColor.trim(), // Trim spaces from backgroundColor
    };
    createSpecialQuiz(quizPayload).then((response) => {
      if (response._id) {
        alert("Special Quiz created successfully!");
        setShowQuizCreation(false);
      } else {
        alert("Special Quiz creation failed!");
        // Reseting all states of the form
        setQuizKey("");
        setStartDate("");
        setError("");
        setName("");
        setDescription("");
        setBackgroundImage("");
        setBackgroundColor("");
        setCurrentPage(1);
      }
    });
  };

  const getNextMonday = (startDate) => {
    const date = new Date(startDate); // Create a new Date object from the input startDate
    const dayOfWeek = date.getUTCDay(); // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)

    // Calculate the number of days to add to get to the next Monday
    const daysToAdd = dayOfWeek === 1 ? 7 : 8 - dayOfWeek; // If it's Monday (1), we add 7 days, else we calculate how many days to add

    date.setUTCDate(date.getUTCDate() + daysToAdd); // Add the days to the current date
    return date.toISOString().split("T")[0]; // Return the new date in YYYY-MM-DD format
  };

  const isFirstPageValid =
    quizKey && startDate && isTuesday(startDate) && !error;

  const isSecondPageValid =
    name && description && backgroundImage && backgroundColor;

  const handleNextPage = () => {
    if (isFirstPageValid) {
      setCurrentPage(2);
    } else {
      setError("Please ensure all fields are valid before proceeding.");
    }
  };

  return (
    <div className={styles.SpecialQuizCreation}>
      <button
        className={styles.BackButton}
        onClick={() => setShowQuizCreation(false)}
      >
        Back
      </button>
      {currentPage === 1 && (
        <>
          <h2>Create Special Quiz</h2>
          <p>Enter the creation key below:</p>
          <input
            type="password"
            placeholder="Enter the creation key"
            className={styles.inputField}
            value={quizKey}
            onChange={(e) => setQuizKey(e.target.value)}
          />
          <br />
          <label className={styles.dateLabel}>Start Date (Tuesday)</label>
          <input
            type="date"
            className={styles.inputField}
            value={startDate}
            onChange={(e) => {
              const selectedDate = e.target.value;
              if (!isTuesday(selectedDate)) {
                setStartDate("");
                setError("Start date must be a Tuesday!");
              } else {
                setError("");
                setStartDate(selectedDate);
              }
            }}
          />
          <br />
          {error && <p className={styles.errorText}>{error}</p>}

          <button
            className={styles.createButton}
            onClick={handleNextPage}
            disabled={!isFirstPageValid}
          >
            Next
          </button>
        </>
      )}
      {currentPage === 2 && (
        <>
          <h2>Special Quiz Details</h2>
          <p>Enter the name below:</p>
          <input
            type="input"
            placeholder="Enter the name"
            className={styles.inputField}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <p>Enter the description below:</p>
          <input
            type="input"
            placeholder="Enter the description"
            className={styles.inputField}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <p>Enter the background image below:</p>
          <div className={styles.selectContainer}>
            <select
              className={styles.dropDown}
              value={backgroundImage}
              onChange={(e) => setBackgroundImage(e.target.value)}
            >
              {backgroundImageOptions.map((image, index) => (
                <option key={index} value={image}>
                  {image}
                </option>
              ))}
            </select>
          </div>
          {backgroundImage && (
            <Icon name={backgroundImage} folder="special_quizzes" size={300} />
          )}
          <p>Select the background color </p>
          <div className={styles.selectContainer}>
            <select
              className={styles.dropDown}
              value={backgroundColor}
              onChange={(e) => setBackgroundColor(e.target.value)}
              style={{
                background: backgroundColor
                  ? `var(--${backgroundColor})`
                  : "white", // Fallback to white
                color: "#fff", // Optional: Make text white for better contrast on dark backgrounds
              }}
            >
              {backgroundColorOptions.map((color, index) => (
                <option key={index} value={color}>
                  {color}
                </option>
              ))}
            </select>
          </div>
          <button
            className={styles.createButton}
            onClick={createQuizHandler}
            disabled={!isSecondPageValid}
          >
            Create Special Quiz
          </button>
        </>
      )}
    </div>
  );
};

export default SpecialQuizCreation;
