import React from "react";
import Typography from "../../atoms/Typography/Typography";
import styles from "./LeaderboardHeadings.module.css";
import Icon from "../../atoms/Icon/Icon";
import { useAuth } from "../../../AthContext";

const LeaderboardHeadings = ({ leaderboardHeadings, isMobile, gameMode }) => {

  const {translateText} = useAuth();

  const leaderboardClass =
    gameMode === 'specialQuizzesWeekly'
      ? styles.leaderboardSpecialHeading
      : gameMode === 'specialQuizzesTotal'
      ? styles.leaderboardTotalSpecialHeading
      : styles.leaderboardHeadings;

  const trophyText =
    gameMode === 'specialQuizzesWeekly'
      ? translateText('mainNav.profileTab.stats.statistics.correctAnswers')
      : 'Total Wins';


  return (
    <div className={leaderboardClass}>
      {gameMode === 'friends' || gameMode === 'global' ? (
        leaderboardHeadings.map((entry, index) => (
          <div className={styles.heading} key={index}>
            <Typography
              variant={isMobile ? 'caption' : 'body2'}
              fontWeight={'font500'}
              color="colorBlack"
              additionalClass={styles.grey}
            >
              {entry}
            </Typography>
          </div>
        ))
      ) : gameMode === 'specialQuizzesWeekly' ? (
        <div className={styles.heading}>
          <Typography
            variant={isMobile ? 'caption' : 'body2'}
            fontWeight={'font500'}
            color="colorBlack"
            additionalClass={styles.grey}
          >
            {leaderboardHeadings[0]}
          </Typography>
        </div>
      ) : gameMode === 'specialQuizzesTotal' ? (
        leaderboardHeadings.map((entry, index) => (
          <div className={styles.heding} key={index}>
            <Typography
              variant={isMobile ? 'caption' : 'body2'}
              fontWeight={'font500'}
              color="colorBlack"
              additionalClass={styles.grey}
            >
              {entry}
            </Typography>
          </div>
        ))
      ) : null}
      <div className={styles.trophy}>
        <Typography
          variant={isMobile ? 'caption' : 'body2'}
          fontWeight={'font500'}
          color="colorBlack"
          additionalClass={`${styles.grey} ${gameMode === 'specialQuizzesWeekly' ? styles.specialHeading : ''}`}
        >
          {gameMode === 'specialQuizzesWeekly' || gameMode === 'specialQuizzesTotal' ? trophyText : null}
        </Typography>
        {gameMode !== 'specialQuizzesWeekly' && gameMode !== 'specialQuizzesTotal' && (
          <Icon folder={'other_icons'} name="goldenTrophy" size={24} />
        )}
      </div>
    </div>
  );
};

export default LeaderboardHeadings;
